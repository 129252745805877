import axios from 'axios'
import config from '../config.json'
import { PATH } from '../../src/data/constants/paths'
import { msalInstance, loginRequest } from './msal'
import { InteractionRequiredAuthError, AuthenticationResult } from '@azure/msal-browser'

export const apiClient = axios.create({
  baseURL: config.backend,
})

const apiScopes = loginRequest.scopes

const acquireToken = async (): Promise<string | null> => {
  try {
    const accounts = msalInstance.getAllAccounts()
    if (accounts.length === 0) {
      await msalInstance.loginRedirect(loginRequest)
      return null
    }

    const accessTokenRequest = {
      scopes: apiScopes,
      account: accounts[0],
    }

    const authResult = await msalInstance.acquireTokenSilent(accessTokenRequest)
    return authResult.accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          scopes: apiScopes,
        })
        return null
      } catch (redirectError) {
        console.error('Error during token redirect:', redirectError)
        return null
      }
    }
    console.error('Error acquiring token:', error)
    return null
  }
}

apiClient.interceptors.request.use(
  async (config) => {
    const token = await acquireToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      window.location.replace(PATH.FORBIDDEN)
    } else if (error.response?.status === 401) {
      msalInstance.clearCache()
      msalInstance.loginRedirect(loginRequest)
    }
    return Promise.reject(error)
  },
)

export default apiClient
